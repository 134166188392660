import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { timeZones } from '../constant/timezones';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  tablemakerURL = environment.config.tablemakerAPI;
  haystackTimezoneMap: any = {};

  constructor(private http: HttpClient) {
    this.haystackTimezoneMap = timeZones;
  }

  public passcodeId: string;

  get passcode(): string {
    return this.passcodeId;
  }

  setPasscode(value: string) {
    this.passcodeId = value;
  }

  // Get QR code Meta data 
  getQRCodeData(passcode: string) {
    return this.http.get(`${this.tablemakerURL}/data/${passcode}/details`);
  }

  getReadById(pointId: string) {
    const data = [pointId];
    return this.http.post(`${this.tablemakerURL}data/${this.passcodeId}/read`, data).pipe(catchError(this.handleError));
  }

  getEntitiesInBuilding(data) {
    return this.http.post(`${this.tablemakerURL}data/${this.passcodeId}/readWithFilter`, data).pipe(catchError(this.handleError));
  }

  getSiteById(siteId) {
    const data = `id==@${siteId}`;
    return this.http.post(`${this.tablemakerURL}data/${this.passcodeId}/readWithFilter`, data).pipe(catchError(this.handleError));
  }

  // returns the IANA timezone from a Haystack timezone
  // e.g. when converting tz from site entity
  getIANATimeZone(timezone: string) {
    if (timezone === 'Calcutta') {
      timezone = 'Kolkata';
    }
    return this.haystackTimezoneMap[timezone];
  }

  getWritablePointData(pointRef) {
    const data = pointRef;
    return this.http.post(`${this.tablemakerURL}data/${this.passcodeId}/pointWriteMany`, data).pipe(catchError(this.handleError));
  }

  getCurrenthisReadMany(pointsList: any) {
    let data = pointsList;
    return this.http.post<any>(`${this.tablemakerURL}data/${this.passcodeId}/hisReadMany`, data).pipe(catchError(this.handleError));
  }

  gethisReadManyInterpolate(pointsList: any, daterange: any, timeZone: any) {
    const format = 'YYYY-MM-DDTHH:mm:ssZ';

    const startDate = dayjs.tz(daterange.startDate, timeZone).format(format);
    const endDate = dayjs.tz(daterange.endDate, timeZone).format(format);

    let data =  {
      "pointIds": pointsList,
      "startDateTime": startDate +' ' +this.getHaystackTimeZone(timeZone),
      "endDateTime": endDate +' '+ this.getHaystackTimeZone(timeZone)
    }

    // // tslint:disable-next-line
    return this.http.post<any>(`${this.tablemakerURL}data/${this.passcodeId}/hisReadManyInterpolate/`, data).pipe(catchError(this.handleError));
  }

  findByQuery(queryString: string) {
    return this.http.post(`${this.tablemakerURL}data/${this.passcodeId}/readWithFilter`, queryString).pipe(catchError(this.handleError));
  }

  // returns Haystack timezone from IANA timezone
  // e.g. when setting a daterange for hisRead op
  getHaystackTimeZone(timezoneIANA: string) {
    return Object.keys(this.haystackTimezoneMap).find(htz => this.haystackTimezoneMap[htz] === timezoneIANA);
  }

  getHaystackDataByQuery(query) {
    return this.http.post(`${this.tablemakerURL}data/${this.passcodeId}/readWithFilter`, query).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  getTaggedFiles(equipId) {
    return this.http.get(`${this.tablemakerURL}data/taggedFiles?passcode=${this.passcodeId}&entityId=${equipId}`).pipe(catchError(this.handleError));
  }
}
