export const environment = {
    version: '1.0.0',
    production: true,
    partnerName: '75f',
    partner: false,
    supportEmail:'75fdevelopment@75f.io',
    config: {
        "stage": "dev",
        "appName": "QRCode-UI",
        "tablemakerAPI": "https://table-maker-api.dev.service.75f.io/"
    }
};
