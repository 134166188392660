import { Component, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from './shared/_services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'QR-code';
  constructor(private location: Location,
    private apiService: ApiService,
    @Inject('LOCALSTORAGE') private localStorage: any
  ) { }

  isPasscodeValid: boolean = false;
  passCode: any = '';
  loading: boolean = true;
  qrCodeMetaData: any = {};

  ngOnInit() {
    const currentUrl: any = this.location.path();
    this.passCode = currentUrl.split('/')[1];
    this.apiService.setPasscode(this.passCode);

    this.localStorage.setItem('certificateLevel', 'L0');
    this.checkPasscodeValidity();
  }

  // Check the validity of the passcode and get passcode metadata
  checkPasscodeValidity() {
    if (this.passCode) {
      this.loading = true;
      this.apiService.getQRCodeData(this.passCode).subscribe((qrCodeDetails: any) => {
        this.qrCodeMetaData = qrCodeDetails;
        this.localStorage.setItem('qrCodeMetaData', JSON.stringify(this.qrCodeMetaData));
        if (this.qrCodeMetaData.enabled) {
          this.isPasscodeValid = true;
        } else {
          this.isPasscodeValid = false;
        }
        this.loading = false;
      }, error => {
        if (error.status === 404) {
          this.isPasscodeValid = false;
          this.loading = false;
        }
      });
    } else {
      this.loading = false;
      this.isPasscodeValid = false;
    }

  }
}
