<div class="container">
    <div class="logo">
        <img [ngClass]="" src="../../../assets/images/logo.png" alt="75F Logo">
    </div>
    <h1 class="errorcode-text ">403</h1>
    <div class="access-not-granted-text">ACCESS NOT GRANTED</div>
    <div class="not-allowed-text">You are not allowed to access this PORTAL</div>
    <div class="dotted-line"></div>
    <div class="support-mail-text">
        If you think this is a mistake, and you should have had access,<span> Please contact<br>
            Support</span>  [<i class="fa fa-envelope envelope-icon" aria-hidden="true"></i><a [href]="partnerSupportMail">{{supportMailId}}</a> ]
    </div>
</div>