import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { HttpClientModule } from '@angular/common/http';
import {
  PucComponentsModule,
} from '@75f/portal-ui-components';
import {
  ConfigurationService
} from '@75f/portal-ui-components';
import { ZoneLayoutComponent } from './components/zone-layout/zone-layout.component';
import { SystemLayoutComponent } from './components/system-layout/system-layout.component';
import { environment } from '../environments/environment';
import { MaterialModule } from "@/shared/material.module";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.setConfig(environment.config);
  };
};



@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    ZoneLayoutComponent,
    SystemLayoutComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    PucComponentsModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    BrowserAnimationsModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    deps: [ConfigurationService],
    multi: true
  },
  { provide: 'LOCALSTORAGE', useFactory: getLocalStorage }
],
  bootstrap: [AppComponent]
})

export class AppModule { }



export function getLocalStorage() {
  return (typeof window !== "undefined") ? window.localStorage : null;
}
