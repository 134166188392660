import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})

export class AccessDeniedComponent {
  partnerName = `partner-${environment.partnerName}`;
  partnerSupportMail = `mailto:${environment.supportEmail}`;
  supportMailId = environment.supportEmail;
}
