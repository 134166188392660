<div *ngIf="!loading">
  <div class="access-denied-layout" *ngIf="!isPasscodeValid">
    <app-access-denied></app-access-denied>
  </div>
  <div class="qr-layout" *ngIf="isPasscodeValid">
    <div *ngIf="qrCodeMetaData.qrCodeType == 'CCU'">
      <app-system-layout [qrCodeMetaData]="qrCodeMetaData"></app-system-layout>
    </div>
    <div *ngIf="qrCodeMetaData.qrCodeType == 'ZONE'">
      <app-zone-layout [qrCodeMetaData]="qrCodeMetaData"></app-zone-layout>
    </div>
  </div>
</div>
  <div *ngIf="loading" class="loader-container">
    <ngx-skeleton-loader
      count="8"
      [theme]="{ 'border-radius': '0', height: '100px' }"
    ></ngx-skeleton-loader>
  </div>
