export const profileTags = {

    hyperStatHpu : {
        relay1AssociationMapping : [
            {
                name: 'Compressor Stage 1',
                value: 0,
                state:true
            },
            {
                name: 'Compressor Stage 2',
                value: 1,
                state:true
            },
            {
                name: 'Compressor Stage 3',
                value: 2,
                state:true
            },
            {
                name: 'Aux Heating Stage 1',
                value: 3,
                state:true
            },
            {
                name: 'Aux Heating Stage 2',
                value: 4,
                state:true
            },
            {
                name: 'Fan Low Speed',
                value: 5,
                state:true
            },
            {
                name: 'Fan Medium Speed',
                value: 6,
                state:true
            },
            {
                name: 'Fan High Speed',
                value: 7,
                state:true
            },
            {
                name: 'Fan Enabled',
                value: 8,
                state:true
            },
            {
                name: 'Occupied Enabled',
                value: 9,
                state:true
            },
            {
                name: 'Humidifier',
                value: 10,
                state:true
            },
            {
                name: 'DeHumidifier',
                value: 11,
                state:true
            },
            {
                name: 'Change Over O Cooling',
                value: 12,
                state:true,
                relay1 : 1
            },
            {
                name: 'Change Over B Heating',
                value: 13,
                state:true,
                relay1 : 1
            },
        ],
        relay2AssociationMapping : [
            {
                name: 'Compressor Stage 1',
                value: 0,
                state:true
            },
            {
                name: 'Compressor Stage 2',
                value: 1,
                state:true
            },
            {
                name: 'Compressor Stage 3',
                value: 2,
                state:true
            },
            {
                name: 'Aux Heating Stage 1',
                value: 3,
                state:true
            },
            {
                name: 'Aux Heating Stage 2',
                value: 4,
                state:true
            },
            {
                name: 'Fan Low Speed',
                value: 5,
                state:true
            },
            {
                name: 'Fan Medium Speed',
                value: 6,
                state:true
            },
            {
                name: 'Fan High Speed',
                value: 7,
                state:true
            },
            {
                name: 'Fan Enabled',
                value: 8,
                state:true
            },
            {
                name: 'Occupied Enabled',
                value: 9,
                state:true
            },
            {
                name: 'Humidifier',
                value: 10,
                state:true
            },
            {
                name: 'DeHumidifier',
                value: 11,
                state:true
            },
            {
                name: 'Change Over O Cooling',
                value: 12,
                state:true,
                relay1 : 1
            },
            {
                name: 'Change Over B Heating',
                value: 13,
                state:true,
                relay1 : 1
            },
        ],
        relay3AssociationMapping : [
            {
                name: 'Compressor Stage 1',
                value: 0,
                state:true
            },
            {
                name: 'Compressor Stage 2',
                value: 1,
                state:true
            },
            {
                name: 'Compressor Stage 3',
                value: 2,
                state:true
            },
            {
                name: 'Aux Heating Stage 1',
                value: 3,
                state:true
            },
            {
                name: 'Aux Heating Stage 2',
                value: 4,
                state:true
            },
            {
                name: 'Fan Low Speed',
                value: 5,
                state:true
            },
            {
                name: 'Fan Medium Speed',
                value: 6,
                state:true
            },
            {
                name: 'Fan High Speed',
                value: 7,
                state:true
            },
            {
                name: 'Fan Enabled',
                value: 8,
                state:true
            },
            {
                name: 'Occupied Enabled',
                value: 9,
                state:true
            },
            {
                name: 'Humidifier',
                value: 10,
                state:true
            },
            {
                name: 'DeHumidifier',
                value: 11,
                state:true
            },
            {
                name: 'Change Over O Cooling',
                value: 12,
                state:true,
                relay1 : 1
            },
            {
                name: 'Change Over B Heating',
                value: 13,
                state:true,
                relay1 : 1
            },
        ],
        relay4AssociationMapping : [
            {
                name: 'Compressor Stage 1',
                value: 0,
                state:true
            },
            {
                name: 'Compressor Stage 2',
                value: 1,
                state:true
            },
            {
                name: 'Compressor Stage 3',
                value: 2,
                state:true
            },
            {
                name: 'Aux Heating Stage 1',
                value: 3,
                state:true
            },
            {
                name: 'Aux Heating Stage 2',
                value: 4,
                state:true
            },
            {
                name: 'Fan Low Speed',
                value: 5,
                state:true
            },
            {
                name: 'Fan Medium Speed',
                value: 6,
                state:true
            },
            {
                name: 'Fan High Speed',
                value: 7,
                state:true
            },
            {
                name: 'Fan Enabled',
                value: 8,
                state:true
            },
            {
                name: 'Occupied Enabled',
                value: 9,
                state:true
            },
            {
                name: 'Humidifier',
                value: 10,
                state:true
            },
            {
                name: 'DeHumidifier',
                value: 11,
                state:true
            },
            {
                name: 'Change Over O Cooling',
                value: 12,
                state:true,
                relay1 : 1
            },
            {
                name: 'Change Over B Heating',
                value: 13,
                state:true,
                relay1 : 1
            },
        ],
        relay5AssociationMapping : [
            {
                name: 'Compressor Stage 1',
                value: 0,
                state:true
            },
            {
                name: 'Compressor Stage 2',
                value: 1,
                state:true
            },
            {
                name: 'Compressor Stage 3',
                value: 2,
                state:true
            },
            {
                name: 'Aux Heating Stage 1',
                value: 3,
                state:true
            },
            {
                name: 'Aux Heating Stage 2',
                value: 4,
                state:true
            },
            {
                name: 'Fan Low Speed',
                value: 5,
                state:true
            },
            {
                name: 'Fan Medium Speed',
                value: 6,
                state:true
            },
            {
                name: 'Fan High Speed',
                value: 7,
                state:true
            },
            {
                name: 'Fan Enabled',
                value: 8,
                state:true
            },
            {
                name: 'Occupied Enabled',
                value: 9,
                state:true
            },
            {
                name: 'Humidifier',
                value: 10,
                state:true
            },
            {
                name: 'DeHumidifier',
                value: 11,
                state:true
            },
            {
                name: 'Change Over O Cooling',
                value: 12,
                state:true,
                relay1 : 1
            },
            {
                name: 'Change Over B Heating',
                value: 13,
                state:true,
                relay1 : 1
            },
        ],
        relay6AssociationMapping : [
            {
                name: 'Compressor Stage 1',
                value: 0,
                state:true
            },
            {
                name: 'Compressor Stage 2',
                value: 1,
                state:true
            },
            {
                name: 'Compressor Stage 3',
                value: 2,
                state:true
            },
            {
                name: 'Aux Heating Stage 1',
                value: 3,
                state:true
            },
            {
                name: 'Aux Heating Stage 2',
                value: 4,
                state:true
            },
            {
                name: 'Fan Low Speed',
                value: 5,
                state:true
            },
            {
                name: 'Fan Medium Speed',
                value: 6,
                state:true
            },
            {
                name: 'Fan High Speed',
                value: 7,
                state:true
            },
            {
                name: 'Fan Enabled',
                value: 8,
                state:true
            },
            {
                name: 'Occupied Enabled',
                value: 9,
                state:true
            },
            {
                name: 'Humidifier',
                value: 10,
                state:true
            },
            {
                name: 'DeHumidifier',
                value: 11,
                state:true
            },
            {
                name: 'Change Over O Cooling',
                value: 12,
                state:true,
                relay1 : 1
            },
            {
                name: 'Change Over B Heating',
                value: 13,
                state:true,
                relay1 : 1
            },
        ],
  
     /*analogOut Associations Array dropdown*/
        analogOutMapping :[
            {
                name: 'Compressor Speed',
                value: 0,
            },
            {
                name: 'Fan Speed',
                value: 1,
            },
            {
                name: 'DCV Damper',
                value: 2,
            }
        ],
    /*analogIn Associations Array dropdown*/
        analogInMapping : [
            {
                name: 'Current TX(0-10Amps)',
                value: 0,
            },
            {
                name: 'Current TX(0-20Amps)',
                value: 1,
            },
            {
                name: 'Current Tx(0-50Amps)',
                value: 2,
            },
            {
                name: 'Key Card Sensor',
                value: 3,
            },
            {
                name: 'Door/Window Sensor(A12)',
                value: 4,
            } 
        ],
        dynamicArrayMapping :[
            ['analogOut1MinCompressorSpeed', 'analogOut1MaxCompressorSpeed'],
            ['analogOut2MinCompressorSpeed', 'analogOut2MaxCompressorSpeed'],
            ['analogOut3MinCompressorSpeed', 'analogOut3MaxCompressorSpeed'],
            ['analogOut1MinFanSpeed', 'analogOut1MaxFanSpeed', 'analogOut1atFanLow', 'analogOut1atFanMedium', 'analogOut1atFanHigh'],
            ['analogOut2MinFanSpeed', 'analogOut2MaxFanSpeed', 'analogOut2atFanLow', 'analogOut2atFanMedium', 'analogOut2atFanHigh'],
            ['analogOut3MinFanSpeed', 'analogOut3MaxFanSpeed', 'analogOut3atFanLow', 'analogOut3atFanMedium', 'analogOut3atFanHigh'],
            ['analogOut1MinDcvDamper', 'analogOut1MaxDcvDamper','Co2DamperOpenRate','co2Threshold','co2Target'],
            ['analogOut2MinDcvDamper', 'analogOut2MaxDcvDamper','Co2DamperOpenRate','co2Threshold','co2Target'],
            ['analogOut3MinDcvDamper', 'analogOut3MaxDcvDamper','Co2DamperOpenRate','co2Threshold','co2Target'],
        ],
        widgets: [
            
            {
                name: 'currentTemp',
                tags: ['current', 'cur', 'temp']
            }, {
                name: 'desiredTempHeating',
                tags: ['desired', 'logical', 'temp', 'heating']
            }, {
                name: 'scheduleType',
                tags: ['zone', 'logical', 'scheduleType']
            }, {
                name: 'desiredTempCooling',
                tags: ['desired', 'logical', 'temp', 'cooling']
            }, {
                name: 'condheatingStage1',
                tags: ['heating', 'cmd', 'stage1']
            }, {
                name: 'condheatingStage2',
                tags: ['heating', 'cmd', 'stage2']
            }, {
                name: 'autoAway',
                tags: ['config', 'auto', 'away','enabled']
            }, {
                name: 'autoForcedOccupied',
                tags: ['auto', 'forced', 'occupancy']
            },
            {
                name: 'hpuOperatingMode',
                tags: ['zone', 'hpu', 'operating', 'mode', 'temp']
            },
            {
                name: 'compressorStage1',
                tags: ['zone', 'hpu', 'compressor', 'stage1']
            },
            {
                name: 'compressorStage2',
                tags: ['zone', 'hpu', 'compressor', 'stage2']
            },
            {
                name: 'compressorStage3',
                tags: ['zone', 'hpu', 'compressor', 'stage3']
            },
            {
                name: 'FanModeCpuHpu',
                tags: ['zone', 'hpu', 'fan', 'mode']
            },
            {
                name: 'auxHeatingStage1',
                tags: ['zone', 'electheating', 'aux', 'stage1']
            },
            {
                name: 'auxHeatingStage2',
                tags: ['zone', 'electheating', 'aux', 'stage2']
            },
  
            {
                name: 'heatpumpChangeoverCooling',
                tags: ['zone', 'hpu', 'changeover', 'cooling']
            },
            {
                name: 'heatpumpChangeoverHeating',
                tags: ['zone', 'hpu', 'changeover', 'heating']
            },
            {
                name: 'modulatingFanSpeedAnalog',
               
                tags: ['fan', 'logical', 'cmd', 'analog']
            },{
                name : 'SupplyWaterTemperature',
                tags : ['water', 'logical', 'supply']
            },
            {
                name: 'modulatingDamperAnalog',
                tags: ['damper', 'dcv', 'analog']
            }, {
                name: 'fanLow',
                tags: ['cmd', 'low', 'fan', 'speed','logical']
            }, {
                name: 'fanMedium',
                tags: ['cmd', 'medium', 'fan', 'speed','logical']
            }, {
                name: 'fanHigh',
                tags: ['cmd', 'high', 'fan', 'speed','logical']
            }, 
            {
                name: 'ModulatingCompressorSpeed',
                tags: ['compressor', 'speed', 'analog', 'logical']
            },
            {
                name: 'doorSensorTh2',
                tags: ['contact', 'door', 'window']
            },{
                name: 'currentTx(0-10)',
                tags: ['logical', 'sensor', 'current', 'transformer']
            },{
                name: 'currentTx(0-20)',
                tags: ['logical', 'sensor', 'current', 'transformer20']
            },
            {
                name: 'currentTx(0-50)',
                tags: ['logical', 'sensor', 'current', 'transformer50']
            },
            {
                name: 'analog1InKeyCardSensor',
                tags: ['logical', 'sensor', 'keycard']
            }, {
                name: 'analog1InDoorSensor1',
                tags: ['logical', 'sensor', 'door','window2']
            },{
                name: 'analog2InKeyCardSensor',
                tags: ['logical', 'sensor', 'keycard2']
            },{
                name: 'analog2InDoorSensor2',
                tags: ['logical', 'sensor', 'door','window3']
            },{
                name: 'airflowTempSensorTh1',
                tags: ['air', 'sensor', 'temp','discharge']
            },{
                name: 'SupplyWaterTemperature',
                tags: ['supply','water','cur','temp']
            },{ 
                name: 'FanModeHsCpuHpu',
                tags: ['zone','sp', 'operation', 'fan', 'mode']
            },{
                name: 'ZoneOperationalMode',
                tags: ['operating','point','enum']
            }, {
                name: 'zoneOccupancy',
                tags: ['zone', 'occupancy', 'mode', 'enum']
            }, {
                name: 'occupancyDetection',
                tags: ['occupancy','zone','sensor','logical']
            }, {
                name: 'Humidity',
                tags: ['humidity', 'sensor', 'cur']
            }, {
                name: 'CO2',
                tags: ['co2', 'sensor', 'cur']
            }, {
                name: 'CO',
                tags: ['co', 'sensor', 'cur']
            }, {
                name: 'NO',
                tags: ['no', 'sensor', 'cur']
            }, {
                name: 'VOC',
                tags: ['voc', 'sensor', 'cur']
            }, {
                name: 'Pressure',
                tags: ['pressure', 'sensor', 'cur']
            }, {
                name: 'Occupancy',
                tags: ['occupancy', 'sensor', 'cur']
            }, {
                name: 'sound',
                tags: ['sound', 'sensor', 'cur']
            }, {
                name: 'Illuminance',
                tags: ['illuminance', 'sensor', 'cur']
            }, {
                name: 'UVI',
                tags: ['uvi', 'sensor', 'cur']
            }, {
                name: 'pm10',
                tags: ['pm10', 'sensor']
            }, {
                name: 'pm2p5',
                tags: ['pm2p5', 'sensor']
            },
            /**currently we are not plotting as per widget sheet in future we may use **/
            // {
            //     name: 'humidifier',
            //     tags: ['control', 'humidifier', 'target', 'his', 'hisInterpolate']
            // }, {
            //     name: 'dehumidifier',
            //     tags: ['control', 'dehumidifier', 'target', 'his', 'hisInterpolate']
            // }, 
            
            {
                name: 'ZoneConditionMode',
                tags: ['zone', 'conditioning', 'mode','sp']
            },
            {
                name:'genericNO',
                tags:['normallyOpen','generic']
            },
            {
                name:'genericNC',
                tags:['normallyClosed','generic']
            }
        ]
    },
  
    hyperStatPipe2 : {
            /*Relays Association Array dropdown*/
            relayAssociationMapping: [
                {
                    name: 'Fan Low Speed',
                    value: 0,
                },
                {
                    name: 'Fan Medium Speed',
                    value: 1,
                },
                {
                    name: 'Fan High Speed',
                    value: 2,
                },
                {
                    name: 'Aux Heating Stage 1',
                    value: 3,
                },
                {
                    name: 'Aux Heating Stage 2',
                    value: 4,
                },
                {
                    name: 'Water Valve',
                    value: 5,
                },
                {
                    name: 'Fan Enabled',
                    value: 6,
                },
                {
                    name: 'Occupied Enabled',
                    value: 7,
                },
                {
                    name: 'Humidifier',
                    value: 8,
                },
                {
                    name: 'DeHumidifier',
                    value: 9,
                },
            ],
  
            /*analogOut Associations Array dropdown*/
            analogOutMapping :[
                {
                    name: 'Water Modulating Valve',
                    value: 0,
                },
                {
                    name: 'Fan Speed',
                    value: 1,
                },
                {
                    name: 'DCV Damper',
                    value: 2,
                }
            ],
  
            /*analogIn Associations Array dropdown*/
            analogInMapping : [
                {
                    name: 'Current TX(0-10Amps)',
                    value: 0,
                },
                {
                    name: 'Current TX(0-20Amps)',
                    value: 1,
                },
                {
                    name: 'Current Tx(0-50Amps)',
                    value: 2,
                },
                {
                    name: 'Key Card Sensor',
                    value: 3,
                },
                {
                    name: 'Door/Window Sensor(A12)',
                    value: 4,
                } 
            ],
  
            /*Dynamic  points creation based on analogIn,analogOut,relay selection*/
            dynamicArrayMapping :[
                ['analogOut1MinWaterValve', 'analogOut1MaxWaterValve'],
                ['analogOut2MinWaterValve', 'analogOut2MaxWaterValve'],
                ['analogOut3MinWaterValve', 'analogOut3MaxWaterValve'],
                ['analogOut1MinFanSpeed', 'analogOut1MaxFanSpeed', 'analogOut1atFanLow', 'analogOut1atFanMedium', 'analogOut1atFanHigh'],
                ['analogOut2MinFanSpeed', 'analogOut2MaxFanSpeed', 'analogOut2atFanLow', 'analogOut2atFanMedium', 'analogOut2atFanHigh'],
                ['analogOut3MinFanSpeed', 'analogOut3MaxFanSpeed', 'analogOut3atFanLow', 'analogOut3atFanMedium', 'analogOut3atFanHigh'],
                ['analogOut1MinDcvDamper', 'analogOut1MaxDcvDamper','Co2DamperOpenRate','co2Threshold','co2Target'],
                ['analogOut2MinDcvDamper', 'analogOut2MaxDcvDamper','Co2DamperOpenRate','co2Threshold','co2Target'],
                ['analogOut3MinDcvDamper', 'analogOut3MaxDcvDamper','Co2DamperOpenRate','co2Threshold','co2Target'],
            ],
            /**Widgets**/
            widgets: [
                    {
                        name: 'currentTemp',
                        tags: ['current', 'cur', 'temp']
                    }, {
                        name: 'desiredTempHeating',
                        tags: ['desired', 'logical', 'temp', 'heating']
                    }, {
                        name: 'scheduleType',
                        tags: ['zone', 'logical', 'scheduleType']
                    }, {
                        name: 'desiredTempCooling',
                        tags: ['desired', 'logical', 'temp', 'cooling']
                    }, {
                        name: 'condheatingStage1',
                        tags: ['heating', 'cmd', 'stage1']
                    }, {
                        name: 'condheatingStage2',
                        tags: ['heating', 'cmd', 'stage2']
                    }, {
                        name: 'autoAway',
                        tags: ['config', 'auto', 'away','enabled']
                    }, {
                        name: 'autoForcedOccupied',
                        tags: ['auto', 'forced', 'occupancy']
                    }, {
                        name: 'modulatingWaterValveAnalog',
                        tags: ['cooling', 'water', 'analog', 'logical']
                    }, {
                        name: 'waterValve',
                        tags: ['water','valve','cooling','heating','logical','relay']
                    }, {
                        name: 'modulatingFanSpeedAnalog',
                        tags: ['fan', 'logical', 'cmd', 'analog']
                    },{
                        name : 'SupplyWaterTemperature',
                        tags : ['water', 'logical', 'supply']
                    },
                    {
                        name: 'modulatingDamperAnalog',
                        tags: ['damper', 'dcv', 'analog']
                    }, {
                        name: 'fanLow',
                        tags: ['cmd', 'low', 'fan', 'speed','logical']
                    }, {
                        name: 'fanMedium',
                        tags: ['cmd', 'medium', 'fan', 'speed','logical']
                    }, {
                        name: 'fanHigh',
                        tags: ['cmd', 'high', 'fan', 'speed','logical']
                    }, {
                        name: 'doorSensorTh2',
                        tags: ['door', 'contact', 'window']
                    },{
                        name: 'currentTx(0-10)',
                        tags: ['logical', 'sensor', 'current', 'transformer']
                    },{
                        name: 'currentTx(0-20)',
                        tags: ['logical', 'sensor', 'current', 'transformer20']
                    },
                    {
                        name: 'currentTx(0-50)',
                        tags: ['logical', 'sensor', 'current', 'transformer50']
                    },
                    {
                        name: 'analog1InKeyCardSensor',
                        tags: ['logical', 'sensor', 'keycard']
                    }, {
                        name: 'analog1InDoorSensor1',
                        tags: ['logical', 'sensor', 'door','window2']
                    },{
                        name: 'analog2InKeyCardSensor',
                        tags: ['logical', 'sensor', 'keycard2']
                    },{
                        name: 'analog2InDoorSensor2',
                        tags: ['logical', 'sensor', 'door','window3']
                    },{
                        name: 'airflowTempSensorTh1',
                        tags: ['air', 'sensor', 'temp','discharge']
                    },{
                        name: 'SupplyWaterTemperature',
                        tags: ['supply','water','cur','temp']
                    },{ 
                        name: 'FanModeHsCpuHpu',
                        tags: ['zone','sp', 'operation', 'fan', 'mode']
                    },{
                        name: 'ZoneOperationalMode',
                        tags: ['operating','point','enum']
                    }, {
                        name: 'zoneOccupancy',
                        tags: ['zone', 'occupancy', 'mode', 'enum']
                    }, {
                        name: 'occupancyDetection',
                        tags: ['occupancy','zone','sensor','logical']
                    }, {
                        name: 'Humidity',
                        tags: ['humidity', 'sensor', 'cur']
                    }, {
                        name: 'CO2',
                        tags: ['co2', 'sensor', 'cur']
                    }, {
                        name: 'CO',
                        tags: ['co', 'sensor', 'cur']
                    }, {
                        name: 'NO',
                        tags: ['no', 'sensor', 'cur']
                    }, {
                        name: 'VOC',
                        tags: ['voc', 'sensor', 'cur']
                    }, {
                        name: 'Pressure',
                        tags: ['pressure', 'sensor', 'cur']
                    }, {
                        name: 'Occupancy',
                        tags: ['occupancy', 'sensor', 'cur']
                    }, {
                        name: 'sound',
                        tags: ['sound', 'sensor', 'cur']
                    }, {
                        name: 'Illuminance',
                        tags: ['illuminance', 'sensor', 'cur']
                    }, {
                        name: 'UVI',
                        tags: ['uvi', 'sensor', 'cur']
                    }, {
                        name: 'pm10',
                        tags: ['pm10', 'sensor']
                    }, {
                        name: 'pm2p5',
                        tags: ['pm2p5', 'sensor']
                    },
                    /**currently we are not plotting as per widget sheet in future we may use **/
                    // {
                    //     name: 'humidifier',
                    //     tags: ['control', 'humidifier', 'target', 'his', 'hisInterpolate']
                    // }, {
                    //     name: 'dehumidifier',
                    //     tags: ['control', 'dehumidifier', 'target', 'his', 'hisInterpolate']
                    // }, 
                    
                    {
                        name: 'ZoneConditionMode',
                        tags: ['zone', 'conditioning', 'mode','sp']
                    },
                    {
                        name:'genericNO',
                        tags:['normallyOpen','generic']
                    },
                    {
                        name:'genericNC',
                        tags:['normallyClosed','generic']
                    }
            ]
    },
  
    hstat_hsplit_cpu: {
        widgets: [{
            name: 'currentTx(0-10)',
            tags: ['current', 'transformer'],
            domainName: 'currentTx10'
        }, {
            name: 'currentTx(0-20)',
            tags: ['current', 'transformer20'],
            domainName: 'currentTx20'
        }, {
            name: 'currentTx(0-50)',
            tags: ['current', 'transformer50'],
            domainName: 'currentTx50'
        }, {
            name: 'currentTemp',
            tags: ['current', 'cur', 'temp'],
            domainName: 'currentTemp'

        }, {
            name: 'desiredTempHeating',
            tags: ['desired', 'logical', 'temp', 'heating'],
            domainName: 'desiredTempHeating'
        }, {
            name: 'desiredTempCooling',
            tags: ['desired', 'logical', 'temp', 'cooling'],
            domainName: 'desiredTempCooling'
        }, {
            name: 'condheatingStage1',
            tags: ['heating', 'cmd', 'stage1'],
            domainName: 'heatingStage1'
        }, {
            name: 'condheatingStage2',
            tags: ['heating', 'cmd', 'stage2'],
            domainName: 'heatingStage2'
        }, {
            name: 'condheatingStage3',
            tags: ['heating', 'cmd', 'stage3'],
            domainName: 'heatingStage3'
        }, {
            name: 'condheatingStage4',
            tags: ['heating', 'cmd', 'stage4'],
            domainName: 'heatingStage4'
        }, {
            name: 'condcoolingStage1',
            tags: ['cooling', 'cmd', 'stage1'],
            domainName: 'coolingStage1'
        }, {
            name: 'condcoolingStage2',
            tags: ['cooling', 'cmd', 'stage2'],
            domainName: 'coolingStage2'
        }, {
            name: 'condcoolingStage3',
            tags: ['cooling', 'cmd', 'stage3'],
            domainName: 'coolingStage3'
        }, {
            name: 'condcoolingStage4',
            tags: ['cooling', 'cmd', 'stage4'],
            domainName: 'coolingStage4'
        }, {
            name: 'autoAway',
            tags: ['config', 'auto', 'away', 'enabled'],
            domainName: 'autoAway'
        }, {
            name: 'autoForcedOccupied',
            tags: ['auto', 'forced', 'occupancy'],
            domainName: 'autoForceOccupied'
        }, {
            name: 'modulatingCoolingAnalog1',
            tags: ['cooling', 'modulating', 'cmd', 'analog', 'logical'],
            domainName: 'coolingSignal'
        }, {
            name: 'modulatingHeatingAnalog1',
            tags: ['heating', 'modulating', 'cmd', 'analog', 'logical', 'elecHeating'],
            domainName: 'heatingSignal'
        }, {
            name: 'fanLow',
            tags: ['cmd', 'low', 'fan', 'speed', 'logical'],
            domainName: 'fanLowSpeed'
        }, {
            name: 'fanMedium',
            tags: ['cmd', 'medium', 'fan', 'speed', 'logical'],
            domainName: 'fanMediumSpeed'
        }, {
            name: 'fanHigh',
            tags: ['cmd', 'high', 'fan', 'speed', 'logical'],
            domainName: 'fanHighSpeed'
        }, {
            name: 'doorSensorTh2',
            tags: ['door', 'sensor', 'window', 'contact']
        }, {
            name: 'analog1InCurrent',
            tags: ['logical', 'sensor', 'current', 'maxVal']
        }, {
            name: 'analog1InKeyCardSensor',
            tags: ['logical', 'sensor', 'keycard']
        }, {
            name: 'analog1InDoorSensor1',
            tags: ['logical', 'sensor', 'door', 'window2']
        }, {
            name: 'analog2InCurrent',
            tags: ['logical', 'sensor', 'current', 'maxVal']
        }, {
            name: 'analog2InKeyCardSensor',
            tags: ['logical', 'sensor', 'keycard2']
        }, {
            name: 'analog2InDoorSensor2',
            tags: ['logical', 'sensor', 'door', 'window3']
        }, {
            name: 'airflowTempSensorTh1',
            tags: ['air', 'sensor', 'temp', 'discharge'],
            domainName: 'supplyAirTempSensor'
        }, {
            name: 'FanModeHsCpuHpu',
            tags: ['zone', 'sp', 'operation', 'fan', 'mode'],
            domainName: 'fanOpMode'
        }, {
            name: 'ZoneOperationalMode',
            tags: ['operating', 'mode', 'enum'],
            domainName: 'operatingMode'
        }, {
            name: 'ZoneConditionMode',
            tags: ['zone', 'conditioning', 'mode', 'sp'],
            domainName: 'conditioningMode'
        }, {
            name: 'occupancyDetection',
            tags: ['occupancy', 'zone', 'sensor', 'logical'],
            domainName: 'zoneOccupancy'
        }, {
            name: 'zoneOccupancy',
            tags: ['zone', 'occupancy', 'mode', 'enum'],
            domainName: 'occupancyMode'
        }, {
            name: 'Humidity',
            tags: ['humidity', 'sensor', 'cur'],
            domainName: 'zoneHumidity'
        }, {
            name: 'CO2',
            tags: ['co2', 'sensor', 'cur'],
            domainName: 'zoneCO2'
        }, {
            name: 'CO',
            tags: ['co', 'sensor', 'cur'],
            domainName: 'zoneCO'
        }, {
            name: 'NO',
            tags: ['no', 'sensor', 'cur'],
            domainName: 'zoneNO'
        }, {
            name: 'VOC',
            tags: ['voc', 'sensor', 'cur'],
            domainName: 'zoneVOC'
        }, {
            name: 'Pressure',
            tags: ['pressure', 'sensor', 'cur'],

        }, {
            name: 'Occupancy',
            tags: ['occupancy', 'sensor', 'cur'],
            domainName: 'zoneOccupancy'
        }, {
            name: 'sound',
            tags: ['sound', 'sensor', 'cur'],
            domainName: 'zoneSound'
        }, {
            name: 'Illuminance',
            tags: ['illuminance', 'sensor', 'cur'],
            domainName: 'zoneIlluminance'
        }, {
            name: 'UVI',
            tags: ['uvi', 'sensor', 'cur']
        }, {
            name: 'pm10',
            tags: ['pm10', 'sensor']
        }, {
            name: 'pm2p5',
            tags: ['pm2p5', 'sensor'],
            domainName: 'zonePm25'
        }, {
            name: 'linearFanSpeed',
            tags: ['fan', 'speed', 'modulating', 'zone'],
            domainName: 'linearFanSpeed'
        }, {
            name: 'predefinedFanSpeed',
            tags: ['fan', 'speed', 'predefined', 'zone'],
            domainName: 'stagedFanSpeed'
        }, {
            name: 'maxAirTemp',
            tags: ['economizing', 'max', 'temp', 'oao'],
            domainName: 'standaloneEconomizingMaxTemperature'
        }, {
            name: 'minAirTemp',
            tags: ['economizing', 'min', 'temp', 'oao'],
            domainName: 'standaloneEconomizingMinTemperature'
        }, {
            name: 'supplyAirTemp',
            tags: ['sensor', 'discharge', 'temp'],
            domainName: 'dischargeAirTemperature'
        }, {
            name: 'mixedAirFlowTemp',
            tags: ['sensor', 'mixed', 'temp'],
            domainName: 'mixedAirTemperature'
        }, {
            name: 'outsideAirDamperPosition',
            tags: ['damper', 'output', 'economizer'],
            domainName: 'oaoDamper'
        }, {
            name: 'returnDamperPosition',
            tags: ['return', 'air', 'damper'],
            domainName: 'returnDamperPosition'
        }, {
            name: 'CO2Threshold',
            tags: ['co2', 'threshold', 'config', 'cpu'],
            domainName: 'co2Threshold'
        }, {
            name: 'returnAirCO2',
            tags: ['co2', 'return', 'air']
        }, {
            name: 'co2DamperOpeningRate',
            tags: ['damper', 'opening', 'rate', 'cpu', 'co2'],
            domainName: 'co2DamperOpeningRate'
        }, {
            name: 'usePerRoomCo2Sensing',
            tags: ['co2', 'config', 'sensing']
        }, {
            name: 'exhaustFanStage1',
            tags: ['exhaust', 'fan', 'stage1', 'cmd'],
            domainName: 'exhaustFanStage1'
        }, {
            name: 'exhaustFanStage2',
            tags: ['exhaust', 'fan', 'stage2', 'cmd'],
            domainName: 'exhaustFanStage2'
        }, {
            name: 'co2WeightedAverage',
            tags: ['co2', 'weighted', 'average'],
            domainName: 'co2WeightedAverage'
        }, {
            name: 'outsideEnthalpy',
            tags: ['outside', 'enthalpy', 'sp', 'cpu'],
            domainName: 'outsideEnthalpy'
        }, {
            name: 'insideEnthalpy',
            tags: ['inside', 'enthalpy', 'sp', 'cpu'],
            domainName: 'insideEnthalpy'
        }, {
            name: 'freeCoolingEconomize',
            tags: ['economizing', 'available', 'oao', 'cpu'],
            domainName: 'economizingAvailable'
        }, {
            name: 'freeCoolingDCV',
            tags: ['dcv', 'available', 'oao', 'cpu'],
            domainName: 'dcvAvailable'
        }, {
            name: 'freeCoolingMat',
            tags: ['mat', 'available', 'oao', 'cpu'],
            domainName: 'matThrottle'
        }, {
            name: 'weatherOutSideTemp',
            tags: ['outsideWeather', 'oao', 'temp', 'sensor'],
            domainName: 'weatherOutsideTemp'
        }, {
            name: 'weatherOutSideHumidity',
            tags: ['outsideWeather', 'oao', 'humidity', 'sensor'],
            domainName: 'weatherOutsideHumidity'
        }, {
            name: 'ductStaticPressure',
            tags: ['pressure', 'static', 'sensor', 'zone'],
            domainName: 'ductStaticPressureSensor'
        }, {
            name: 'supplyAirHumiditySensor',
            tags: ['discharge', 'air', 'humidity', 'sensor'],
            domainName: 'dischargeAirHumiditySensor'

        }, {
            name: 'filterNO',
            tags: ['filter', 'normallyOpen', 'sensor', 'zone'],
            domainName: 'filterStatusNO'
        }, {
            name: 'filterNC',
            tags: ['filter', 'normallyClosed', 'sensor', 'zone'],
            domainName: 'filterStatusNC'
        }, {
            name: 'condensateNO',
            tags: ['condensate', 'normallyOpen', 'sensor', 'zone'],
            domainName: 'condensateStatusNO'
        }, {
            name: 'condensateNC',
            tags: ['condensate', 'normallyClosed', 'sensor', 'zone'],
            domainName: 'condensateStatusNC'
        }, {
            name: 'pressure0-2',
            tags: ['pressure2in', 'sensor', 'pressure', 'zone'],
            domainName: 'ductStaticPressureSensor1_2'
        }, {
            name: 'pressure0-1',
            tags: ['pressure1in', 'sensor', 'pressure', 'zone'],
            domainName: 'ductStaticPressureSensor1_1'
        }, {
            name: 'pressure0-10',
            tags: ['pressure10in', 'sensor', 'pressure', 'zone'],
            domainName: 'ductStaticPressureSensor1_10'
        }, {
            name: 'genericResistance8',
            tags: ['generic', 'sensor', 'resistance', 'zone'],
            domainName: 'thermistorInput'
        }, {
            name: 'genericVoltage8',
            tags: ['generic', 'sensor', 'volt', 'zone'],
            domainName: 'voltageInput'
        }, {
            name: 'outsideAirTempSensor',
            tags: ['sensor', 'outside', 'air', 'temp'],
            domainName: 'outsideTemperature',
        }, {
            name: 'genericNO',
            tags: ['normallyOpen', 'generic'],
            domainName: 'genericAlarmNO'
        }, {
            name: 'genericNC',
            tags: ['normallyClosed', 'generic'],
            domainName: 'genericAlarmNC'
        }, {
            name: 'prepurgestatus',
            tags: ['prePurge', 'run', 'sensor'],
            domainName: 'prePurgeStatus'
        }, {
            name: 'currentTx(0-30)',
            domainName: 'currentTx30'
        }, {
            name: 'currentTx(0-60)',
            domainName: 'currentTx60'
        }, {
            name: 'currentTx(0-100)',
            domainName: 'currentTx100'
        }, {
            name: 'currentTx(0-120)',
            domainName: 'currentTx120'
        }, {
            name: 'currentTx(0-150)',
            domainName: 'currentTx150'
        }, {
            name: 'currentTx(0-200)',
            domainName: 'currentTx200'
        },
        ],
    },
  
    
    vav_terminal: {
        widgets: [{
            name: 'humidity',
            tags: ['humidity', 'vav'],
            domainName: 'zoneHumidity'
        },
        {
            name: 'desiredTempHeating',
            tags: ['desired', 'vav', 'temp', 'heating'],
            domainName: 'desiredTempHeating'
        },
        {
            name: 'targetMinInsideHumidty',
            tags: ['target', 'vav', 'min', 'humidity', 'inside'],
            domainName: ''
        },
        {
            name: 'targetMaxInsideHumidty',
            tags: ['target', 'vav', 'max', 'humidity', 'inside'],
            domainName: ''
        },
        {
            name: 'zoneOccupancy',
            tags: ['zone', 'occupancy', 'mode', 'vav'],
            domainName: 'occupancyMode'
        },
        {
            name: 'desiredTempCooling',
            tags: ['desired', 'vav', 'temp', 'cooling'],
            domainName: 'desiredTempCooling'
        },
        {
            name: 'co2',
            tags:['co2', 'vav', 'air'],
            domainName: 'zoneCO2'
        },
        {
            name: 'voc',
            tags: ['voc', 'vav', 'air'],
            domainName: 'zoneVOC'
        },
        {
            name: 'zoneCO2Target',
            tags: ['co2', 'target', 'vav'],
            domainName: 'vavZoneCo2Target'
        },
        {
            name: 'zoneVOCTarget',
            tags: ['voc', 'target', 'vav'],
            domainName: 'vavZoneVocTarget'
        },
        {
            name: 'maxCoolingDamperPos',
            tags: ['max', 'vav', 'cooling', 'damper', 'pos'],
            domainName: 'maxCoolingDamperPos'
        },
        {
            name: 'enteringAirTemp',
            tags: ['temp', 'vav', 'air', 'entering', 'sensor'],
            domainName: 'enteringAirTemp'
        },
        {
            name: 'scheduleType',
            tags: ['zone', 'vav', 'scheduleType'],
            domainName: 'scheduleType'
        },
        {
            name: 'dischargeAirTemp',
            tags: ['temp', 'vav', 'air', 'discharge', 'sensor'],
            domainName: 'dischargeAirTemp'
        },
        {
            name: 'maxHeatingDamperPos',
            tags: ['max', 'vav', 'heating', 'damper', 'pos'],
            domainName: 'maxHeatingDamperPos'
        },
        {
            name: 'minCoolingDamperPos',
            tags: ['min', 'vav', 'cooling', 'damper', 'pos'],
            domainName: 'minCoolingDamperPos'
        },
        {
            name: 'minHeatingDamperPos',
            tags: ['min', 'vav', 'heating', 'damper', 'pos'],
            domainName: 'minHeatingDamperPos'
        },
        {
            name: 'occupancyDetection',
            tags: ['occupancy', 'detection', 'zone'],
            domainName: 'occupancyDetection'
        },
        {
            name: 'damperPos',
            tags: ['damper', 'vav', 'normalized'],
            domainName: 'normalizedDamperCmd'
        },
        {
            name: 'reheatPosition',
            tags: ['reheat', 'vav', 'valve'],
            domainName: 'reheatCmd'
        },
        {
            name: 'zonePriority',
            tags: ['zone', 'dynamic', 'priority'],
            domainName: 'zoneDynamicPriority'
        },
        {
            name: 'cfmAirFlowVolume',
            tags: ['air', 'flow', 'trueCfm'],
            domainName: 'airFlowSensor'
        },
        {
            name: 'cfmMinHeating',
            tags: ['heating', 'min', 'trueCfm'],
            domainName: 'minCFMReheating'
        },
        {
            name: 'cfmMaxHeating',
            tags: ['heating', 'max', 'trueCfm'],
            domainName: 'maxCFMReheating'
        },
        {
            name: 'cfmMinCooling',
            tags: ['cooling', 'min', 'trueCfm'],
            domainName: 'minCFMCooling'
        }, {
            name: 'cfmMaxCooling',
            tags: ['cooling', 'max', 'trueCfm'],
            domainName: 'maxCFMCooling'
        },
        {
            name: 'autoAway',
            tags: ['vav', 'auto', 'away', 'config'],
            domainName: 'autoAway'
        },
        {
            name: 'autoForcedOccupied',
            tags: ['vav', 'auto', 'forced'],
            domainName: 'autoForceOccupied'
        },
        {
            name: 'Occupancy',
            tags: ['occupancy', 'sensor', 'cur'],
            domainName: ''
        },
        {
            name: 'CO',
            tags: ['co', 'sensor', 'cur'],
            domainName: ''
        },
        {
            name: 'NO',
            tags: ['no', 'sensor', 'cur'],
            domainName: ''
        },
        {
            name: 'UVI',
            tags: ['uvi', 'sensor', 'cur'],
            domainName: ''
        },
        {
            name: 'CO2Equivalent',
            tags: ['co2Equivalent', 'sensor', 'cur'],
            domainName: ''
        },
        {
            name: 'Pressure',
            tags: ['pressure', 'sensor', 'cur'],
            domainName: 'pressureSensor'
        },
        {
            name: 'sound',
            tags: ['sound', 'sensor', 'cur'],
            domainName: ''
        },
        {
            name: 'Illuminance',
            tags: ['illuminance', 'sensor', 'cur'],
            domainName: ''
        },
        {
            name: 'seriesFan',
            tags: ['series', 'fan'],
            domainName: 'seriesFanCmd'
        },
        {
            name: 'parallelFan',
            tags: ['parallel', 'fan'],
            domainName: 'parallelFanCmd'
        },
        {
            name: 'currentTemp',
            tags: ['current', 'vav', 'temp'],
            domainName: 'currentTemp'
        },{
            name: 'airFlowSetpoint',
            domainName: 'airFlowSetpoint'
        },{
            name: 'dischargeAirTempSetpoint',
            domainName: 'dischargeAirTempSetpoint'
        },{
            name: 'calculativeDamper',
            domainName: 'damperCmdCal',
        }, {
            name: 'calculativeReheat',
            domainName:'reheatCmdCal',
        }
        ]
    }
    
  
  }